<template>
  <div>
    <div class="container">
      <h1 class="mr-sm-4 header-tablepage mb-3">Setting</h1>
      <div v-for="form of autoReportSetting" :key="form.autoreport_id">
        <div class="title-tabs content-between">
          <div>
            <b-form-checkbox
              id="checkbox-1"
              v-model="status"
              name="checkbox-1"
              :value="true"
              :unchecked-value="false"
            >
              {{ form.autoreport_name }}
            </b-form-checkbox>
          </div>
          <div class="cursor-pointer"><u>Setting</u></div>
        </div>
        <div class="bg-white text-black py-3 px-4">
          <b-row>
            <b-col md="6">
              <InputSelect
                title="Day"
                name="Day"
                isRequired
                v-model="form.day_id"
                v-bind:options="options.setting.days"
                valueField="dayId"
                textField="dayName"
              >
                <template v-slot:option-first>
                  <b-form-select-option value="0" disabled
                    >-- Select Days --</b-form-select-option
                  >
                  <!-- <b-form-select-option :value="null">All</b-form-select-option> -->
                </template>
              </InputSelect>
            </b-col>
            <b-col md="6">
              <InputText
                textFloat="Time"
                placeholder=""
                type="time"
                isRequired
                v-model="form.time"
                name="times"
              />
            </b-col>
            <b-col md="6">
              <InputSelect
                title="Formats"
                name="formats"
                isRequired
                v-model="form.format_id"
                v-bind:options="options.setting.formats"
                valueField="formatId"
                textField="formatName"
              >
                <template v-slot:option-first>
                  <b-form-select-option value="0" disabled
                    >-- Select Format --</b-form-select-option
                  >
                  <!-- <b-form-select-option :value="null">All</b-form-select-option> -->
                </template>
              </InputSelect>
            </b-col>
            <b-col md="6">
              <InputText
                type="text"
                textFloat="Email"
                isRequired
                placeholder="Please Input Email"
                v-model="form.emails"
                name="email"
              />
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: {
        setting: {
          days: [],
          formats: [],
        },
      },
      status: true,
      form: {
        auto_report_day_id: 0,
        time: "",
        auto_report_format_id: 0,
        emails: [],
      },
      autoReportSetting: [
        {
          autoreport_id: 1,
          autoreport_name: "",
          time: "",
          day_id: 0,
          day_name: "",
          format_id: 0,
          format_name: "",
          emails: [],
        },
      ],
    };
  },
  created() {
    this.getSetting();
  },
  methods: {
    async getSetting() {
      const res = await this.axios(`/AutoReport/setting_auto_report`);

      this.options = res.data.detail;
      this.autoReportSetting = res.data.detail.autoReportSetting;
    },
  },
};
</script>

<style></style>
